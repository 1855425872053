export default {
	methods: {
		downloadFile (url, fileName) {
			fetch(url)
				.then(response => response.blob())
				.then(blob => {
					const link = document.createElement('a')
					link.href = URL.createObjectURL(blob)
					link.download = fileName
					link.click()
				})
		}
	}
}
