<template>
	<ViewLayout>
		<template #header-title>
			{{ 'Confirmation Pages' | useLabels }}
		</template>
		<template #header-caption>
			{{ 'Add, edit and manage confirmation pages.' | useLabels }}
		</template>
		<template #content>
			<slot name="content" />
		</template>
		<template #footer>
			<slot name="footer" />
		</template>
	</ViewLayout>
</template>
<script>
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
export default {
	components: { ViewLayout }
}
</script>
